:root {
  /* dark */
  --black: #3b3b3b;
  --gray: #8f9db6;
  --white: #d4d4d4;
  --orange: #ef8354;
  --dark: #4f5d75;
  --accent: #545970;
  --error: #ec6d62;
  --primary: #6247aa;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Prevent adjustments of font size after orientation changes in iOS. */
  -webkit-text-size-adjust: 100%; /* 2 */
  touch-action: manipulation;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-size: 1rem;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-weight: 400;
  line-height: 1.6;
  /* overflow-x: hidden; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  outline: none;
  text-decoration: none;
}

img,
button {
  outline: none;
  border: none;
}

button {
  cursor: pointer;
}

img {
  border: none;
  max-width: 100%;
  user-select: none;
  vertical-align: middle;
}

button {
  border: 0;
  padding: 0;
  background: none;
  user-select: none;
  text-decoration: none;
  font-size: inherit;
  outline: none;
  font-family: inherit;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

input,
textarea {
  border: none;
  font-family: inherit;
  -webkit-appearance: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
  padding: 0;
  font-family: inherit;
}

strong,
b {
  font-weight: 700;
}

ul {
  list-style: none;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

.radio-group {
  display: flex;
  margin-top: 10px;
}

.radio-group div {
  padding-left: 10px;
}

.radio-group div:first-child {
  padding-left: 0;
}

[type='radio'] {
  -webkit-appearance: radio;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

@media screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }
}
